<template>
    <v-card v-if="job" :loading="job.id" class="flex-grow-1 mb-6 shadow-lg">
      <v-card-text class="pl-9 pr-9">
        <v-row>
          <v-col cols="12" md="2">
            <v-sheet class="mx-auto" :max-width="$vuetify.breakpoint.xs ? '150' : '100%'">
              <school-logo v-if="Object.keys(school).length" :filename="school.logo" />
            </v-sheet>
            
            <div v-if="$store.getters['jobs/isOwner'](job)">
              <v-btn
                @click="togglePrivate(job)"
                color="primary" 
                class="mt-2"
                block
                small
              >
                Make Job {{ job.private ? 'Public' : 'Private' }}
              </v-btn>

              <div class="text-center mt-3">
                <div 
                  class="font-weight-bold"
                  :class="job.private ? 'accent--text' : 'success--text'"
                >
                  {{ job.private ? 'Private' : 'Public' }}
                </div>
                
                <div
                  v-if="job.reference"
                  class="font-weight-bold"
                >
                  Ref# {{ job.reference }}
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="10" :class="{ 'pt-0' : $vuetify.breakpoint.xs }">
            <v-row no-gutters>
              <v-col cols="12">
                <div class="d-flex justify-space-between align-center mb-3 gap-10" :class="{ 'flex-wrap' : $vuetify.breakpoint.xs }">
                  <span class="text-h5 font-weight-bold text-capitalize" :class="{ 'd-block mb-3' : $vuetify.breakpoint.xs }" :style="{ width: $vuetify.breakpoint.xs ? '100%' : 'auto' }">
                    <router-link
                      :to="{ name: 'JobProfile', params: { id: job.id }, query: { template: true } }"
                      class="text--darken-2 font-weight-black primary--text text-capitalize text-decoration-none underline-on-hover"
                    >
                      {{ job.title }}
                    </router-link>
                  </span>
                </div>

                <v-divider class="my-3"></v-divider>
                <v-row no-gutters>
                  <v-col cols="12" md="7">
                    <div v-if="job.school" class="mb-1">
                      <v-icon left small>mdi-warehouse</v-icon>
                      <span>{{ job.school.name }}</span>
                    </div>
                    <a 
                      v-if="school && school.address"
                      class="d-flex align-center mb-1 text-capitalize text-decoration-none"
                      :href="$mapLink(school.address.latitude, school.address.longitude)"
                      target="_blank"
                    >
                      <v-icon color="primary" left small>mdi-map-marker-outline</v-icon>
                      <span>{{ [`${school.address.street_number || '' } ${school.address.route || ''}`, school.address.locality, school.address.administrative_area_level_1].join(', ') }}</span>
                    </a>
                    <div class="d-flex align-center mb-1">
                      <v-icon left small>mdi-account</v-icon>
                      <span>{{ job.position }}</span>
                    </div>
                    <div>
                      <v-icon left small>mdi-calendar-multiselect</v-icon>
                      <span>{{ job.contractLength }}</span>
                    </div>
                  </v-col>
                </v-row>

                <v-row class="gap-10 mt-3" no-gutters>
                  <v-col>
                    <p class="job-description body-1 ls-0">
                      {{ job.description }} 
                    </p>
                  </v-col>
                </v-row>
                <v-row align="center" no-gutters>
                  <v-col cols="12" v-if="$store.getters['jobs/isOwner'](job)" :class="{ 'mt-5' : $vuetify.breakpoint.xs }">
                    <div class="d-flex gap-10 justify-start justify-sm-end">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn @click="duplicateJob(job)" v-on="on" icon small>
                            <v-icon>mdi-content-duplicate</v-icon>
                          </v-btn>
                        </template>
                        <span class="caption">Copy</span>
                      </v-tooltip>
                      
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            :to="{ name: 'JobUpdate', params: { id: job.id }, query: { template: true } }"
                            v-on="on"
                            icon
                            small
                          >
                            <v-icon>mdi-pencil-outline</v-icon>
                          </v-btn>
                        </template>
                        <span class="caption">Edit template or create job from template</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn @click="confirmDelete()" v-on="on" icon small>
                            <v-icon>mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </template>
                        <span class="caption">Delete</span>
                      </v-tooltip>
                    </div>
                  </v-col>
                  <v-col v-else>
                    <div class="text-right">
                      <v-chip
                        v-if="user && user.role == 'jobseeker' && $store.getters['apply/getApplicantStatus'](job.id) != null"
                        outlined
                        label
                        small
                      >
                        Job Status:
                        <span 
                        :class="$store.getters['jobs/getClassStatus']($store.getters['apply/getApplicantStatus'](job.id))"
                        class="font-weight-medium ml-2">{{
                          $store.getters['apply/getApplicantStatus'](job.id)
                        }}</span>
                      </v-chip>
                      
                      <div v-if="applied && job.status !== 'Closed'" class="mt-2">
                        <a @click.prevent="openWithdrawDialog()" class="underline-on-hover grey--text">
                          Withdraw Application
                        </a>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- CONFIRM DIALOG -->
      <confirm-delete
        :show="deleteDialog"
        :message='`Delete job ad "${job.title}"? All teachers who applied for this job will be notified that the job has been closed.`'
        :deleting="status.deleting"
        @cancel="closeDeleteDialog()"
        @confirmed="deleteConfirmed()"
      ></confirm-delete>

      <!-- CONFIRM CLOSE JOB AD -->
      <confirm-delete
        :show="closeJobAdDialog"
        :message='`Move job ad "${job.title}" status to closed? All teachers who applied for this job will be notified that the job has now closed.`'
        :deleting="status.closing"
        @cancel="closeCloseAddDialog()"
        @confirmed="closeJobAdConfirmed()"
      ></confirm-delete>
      
      <!-- CONFIRM WITH APPLICATION -->
      <confirm-delete
        :show="withdrawDialog"
        :message='`Are you sure you want to withdraw your job application?`'
        :deleting="applyStatus.withdrawing"
        @cancel="closeWithdrawDialog()"
        @confirmed="withDrawConfirmed()"
      ></confirm-delete>
    </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import moment from 'moment'
import _omit from 'lodash/omit'
import SchoolLogo from '../school/SchoolLogo'
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      deleteDialog: false,
      showNote: false,
      withdrawDialog: false,
      closeJobAdDialog: false,
      toWithdraw: null,
    }
  },
  
  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: {
    job: Object,
    withdrawn: Boolean,
    applied: Boolean,
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    SchoolLogo
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      jobStatusArray: (state) => state.jobs.jobStatus,
      status: (state) => state.jobs.status,
      applyStatus: (state) => state.apply.status,
    }),

    school: function () {
      return this.$store.getters['schools/userSchool'](this.job.useruid)
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('jobs', ['deleteJob', 'saveNotes', 'closeJob']),
    ...mapActions('job', ['togglePrivate']),
    ...mapActions('apply', ['withDrawApplication']),
    ...mapActions('schools', ['getSchoolByUid']),

    getClassStatus(status) {
      let returnClass = ["warning--text", "default--text", "success--text", "red--text"];
      
      if (status == this.jobStatusArray.inprogress || status == 'Inprogress') {
        return returnClass[0]
      } 
      else if (status == this.jobStatusArray.hired) {
        return returnClass[2]
      } 
      else if (status == this.jobStatusArray.declined || status == 'Declined') {
        return returnClass[3]
      } 
      else {
        return returnClass[1]
      }
    },

    confirmDelete() {
      this.deleteDialog = true
    },

    closeDeleteDialog() {
      this.deleteDialog = false
    },

    newestJob(timestamp) {
      let oneWeek = moment().subtract(7, 'days')
      oneWeek = moment(oneWeek).valueOf()
      if(oneWeek <= timestamp) {
        return true
      }else{
        return false
      }
    },

    openWithdrawDialog() {
      this.withdrawDialog = true
    },
    
    closeWithdrawDialog() {
      this.withdrawDialog = false
    },

    withDrawConfirmed() {
      Promise.all([this.withDrawApplication(this.job.id)])
      .then(() => {
        this.closeWithdrawDialog()

        let user = this.$store.getters['users/userData'](this.job.useruid) 

          /*------------------------------------------------------------------------------
           * SEND EMAIL NOTIFICATION
           *----------------------------------------------------------------------------*/
          let email = {
            recipient: user.email,
            subject: `Job Application Withdrawn`,
            message: `
              Hello ${user.firstName},<br>
              <br>
              We wish to advise that the following applicant has withdrawn from one of your job ads currently listed with TeacherFinder.<br>
              Job applicant: ${this.user.firstName} ${this.user.lastName}<br>
              Job: ${this.job.title}<br>
              Date: ${this.formatContractDates()}<br>
              You can review your job ad and see other candidates here: <a href="${window.location.origin}/my-account">${window.location.origin}/my-account</a> <br>
              <br>
              Regards,<br>
              The Team at TeacherFinder
            `
          }
  
          this.$store.dispatch('notifications/send', email)
      })
    },

    deleteConfirmed() {
      Promise.all([this.deleteJob(this.job)])
      .then(() => {
        this.closeDeleteDialog()
      })
    },

    duplicateJob(job) {
      let data = _omit(job, ['ref', 'id', 'attachments'])
      this.$store.commit('job/setJob', data)
      this.$store.commit('job/duplicatingState', job.id)
      this.$router.push({ name: 'Job' })
    },

    saveNote(job) {
      Promise.all([this.saveNotes(job)])
      .then(() => this.showNote = false)
    },

    closeJobAd() {
      this.closeJobAdDialog = true
    },

    closeCloseAddDialog() {
      this.closeJobAdDialog = false
    },

    closeJobAdConfirmed() {
      Promise.all([this.closeJob(this.job)])
      .then(() => {
        this.closeCloseAddDialog()
      })
    },

    formatContractDates() {
      let dates = this.job.contractDates
      let messages = []

      if (dates && dates.length) {
        dates.forEach(range => {
          messages.push(`${this.$options.filters.simpleDate(range[0])} - ${this.$options.filters.simpleDate(range[0])}`)
        })
      }
      
      return messages.join(', ')
    },

    getStartDate() {
      let startDate = null

      if (this.job.contractDates && this.job.contractDates.length) {
        this.job.contractDates.forEach((dates, i) => {
          if (i == 0) {
            startDate = moment(dates[0]).valueOf()
          }
          else {
            startDate = moment(dates[0]).valueOf() < startDate ? moment(dates[0]).valueOf() : startDate
          }
        })
      }

      return startDate
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (!Object.keys(this.school).length) {
       let user = firebase.auth().currentUser
      if (user) this.getSchoolByUid(user.uid)
    }
  }
}
</script>