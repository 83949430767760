<template>
  <div class="flex-grow-1">
    <AdminTitle title="Job Templates"></AdminTitle>

    <div>
      <preloader v-if="status.gettingTemplates" message="Please wait..." />

      <custom-alert v-if="!status.gettingTemplates && !templates.length">
        You don't have any job template.
      </custom-alert>

      <div v-if="templates.length && !status.gettingTemplates">
        <!-- Job Card -->
          <job-template
            v-for="job in templates" 
            :key="job.id"
            :job="job"
            template
          />
        <!-- End Job Card -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import JobTemplate from './JobTemplate'

export default {
  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: (state) => state.jobs.status,
      templates: (state) => state.jobs.templates,
    })
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('jobs', [
      'getTemplates'
    ])
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    JobTemplate
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.getTemplates()
  }
}
</script>